<template>
  <div>
    <snackbar ref="snackbar" :message="snackbarMessage"></snackbar>
    <div class="text-h6 mt-4">Update settings on user level.</div>
    <v-divider class="mt-8 mb-4" color="default"></v-divider>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="search"
          outlined
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          dense
          clearable
        ></v-text-field>
      </v-col>
      <v-col
        cols="6"
        class="text-right"
        v-if="
          getAuthUser.user_role === 'master' ||
          getAuthUser.user_type === 'admin'
        "
      >
        <v-btn @click.stop="openUserForm('add')" color="primary" outlined>
          <v-icon class="mr-2">mdi-plus</v-icon>
          New User
        </v-btn>
      </v-col>
    </v-row>

    <v-divider class="my-4" color="default"></v-divider>
    <v-data-table
      :search="search"
      :headers="_headers"
      :items="selectedUser"
      class="elevation-1"
    >
      <template v-slot:item.user_type="{ item }">
        {{ capitalizeFirstLetter(item.user_type) }}
      </template>

      <template v-slot:item.user_role="{ item }">
        {{ capitalizeFirstLetter(item.user_role) }}
      </template>

      <template v-slot:item.organizations="{ item }">
        <v-chip
          x-small
          label
          class="mr-2 my-1"
          v-for="(organization, index) in item.organizations"
          :color="organization.color"
          dark
          :key="index"
        >
          {{ organization.name }}
        </v-chip>
      </template>
      <template v-slot:item.accounts="{ item }">
        <v-chip
          x-small
          label
          class="mr-2 my-1"
          v-for="(account, index) in item.accounts"
          color="primary"
          dark
          :key="index"
        >
          {{ account.name | ucfirst }}
        </v-chip>
      </template>

      <template v-slot:item.actions="{ item }">

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" small class="mr-2" @click.stop="openUserForm('edit', item.id)">mdi-pencil</v-icon>
          </template>
          <span>Edit User</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              @click="sendPasswordResetMail(item.id)"
              class="mr-2"
              v-on="on"
              v-bind="attrs"
              v-if="
                getAuthUser.user_role === 'master' &&
                getAuthUser.user_type === 'admin'
              "
              >mdi-email-sync-outline</v-icon
            >
          </template>
          <span>Reset Password</span>
        </v-tooltip>

        <v-icon
          small
          @click="deleteItem(item)"
          v-if="
            getAuthUser.id !== item.id &&
            getAuthUser.user_role === 'master' &&
            item.user_type !== 'admin'
          "
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <user-form
      ref="userForm"
      @close-form="closeUserForm"
      @message="message"
      :formType="formType"
      :dialogState="userFormState"
    ></user-form>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Snackbar from "@/components/Snackbar";
const UserForm = () => import(/* webpackChunkName: "user-form" */ "./UserForm");
export default {
  name: "UserManagement",
  components: { UserForm, Snackbar },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    search: "",
    locations: [],
    headers: [
      {
        text: "First Name",
        value: "first_name",
        class: "text-center text-truncate",
      },
      {
        text: "Last Name",
        value: "last_name",
        class: "text-center text-truncate",
      },
      {
        text: "Job Title",
        value: "job_title",
        class: "text-center text-truncate",
      },
      {
        text: "Organization",
        value: "organizations",
        class: "text-center text-truncate",
      },
      {
        text: "Account",
        value: "accounts",
        class: "text-center text-truncate",
      },
      {
        text: "Email",
        value: "email",
        class: "text-center text-truncate",
      },
      //{text: 'Phone', value: 'phone'},
      {
        text: "User Type",
        value: "user_type",
        class: "text-center text-truncate",
      },
      {
        text: "User Role",
        value: "user_role",
        class: "text-center text-truncate",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        width: 120,
        class: "text-center text-truncate",
      },
    ],
    formType: "add",
    userFormState: false,
    snackbarMessage: "",
  }),
  computed: {
    _headers() {
      return this.headers.filter((x) => {
        if (x.value === "actions") {
          return this.getAuthUser.user_role === "master";
        } else if (x.value === "user_type") {
          return this.getAuthUser.user_type === "admin" ? true : false;
        } else if (x.value === "user_role") {
          return this.getAuthUser.user_role === "master" ? true : false;
        }
        return true;
      });
    },
    selectedUser() {
      return _.reject(this.getUsers, (u) => u.user_type === "buyer");
    },
    ...mapGetters([
      "getUsers",
      "getLocations",
      "getAuthUser",
      "getUsersLoadStatus",
      "getUserDeleteStatus",
      "getUserErrors",
      "getEmailSentStatus",
    ]),
  },
  methods: {
    capitalizeFirstLetter(words) {
      if (words) {
        words = words.replace("_", " ");
        let separateWord = words.toLowerCase().split(" ");
        for (let i = 0; i < separateWord.length; i++) {
          separateWord[i] =
            separateWord[i].charAt(0).toUpperCase() +
            separateWord[i].substring(1);
        }
        return separateWord.join(" ");
      }
    },
    openUserForm(state, id = null) {
      this.formType = state;
      if (state === "edit") this.setUserById(id);
      if (this.$refs.userForm) this.$refs.userForm.initForm(state);
      this.userFormState = true;
    },
    closeUserForm() {
      this.userFormState = false;
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    async deleteItem(item) {
      this.editedIndex = this.getUsers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      await this.deleteUser(this.editedItem);
      if (this.getUserDeleteStatus === 3) {
        this.snackbarMessage = this.getUserErrors.error;
        this.$refs.snackbar.type = "error";
        this.$refs.snackbar.show = true;
      }
    },
    async sendPasswordResetMail(id) {
      await this.setUserById(id);
      await this.passwordResetMail();
      if (this.getEmailSentStatus === 2) {
        this.snackbarMessage = "Email Sent Successfully";
        this.$refs.snackbar.type = "success";
        this.$refs.snackbar.show = true;
      } else if (this.getEmailSentStatus === 3) {
        this.snackbarMessage = "Email Not Sent";
        this.$refs.snackbar.type = "error";
        this.$refs.snackbar.show = true;
      }
    },
    message(msg, type) {
      this.snackbarMessage = msg;
      this.$refs.snackbar.type = type;
      this.$refs.snackbar.show = true;
    },
    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    ...mapMutations(["setUserById"]),
    ...mapActions(["deleteUser", "passwordResetMail"]),
  },
};
</script>
