var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('snackbar',{ref:"snackbar",attrs:{"message":_vm.snackbarMessage}}),_c('div',{staticClass:"text-h6 mt-4"},[_vm._v("Update settings on user level.")]),_c('v-divider',{staticClass:"mt-8 mb-4",attrs:{"color":"default"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"outlined":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","dense":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(
        _vm.getAuthUser.user_role === 'master' ||
        _vm.getAuthUser.user_type === 'admin'
      )?_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.openUserForm('add')}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" New User ")],1)],1):_vm._e()],1),_c('v-divider',{staticClass:"my-4",attrs:{"color":"default"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"headers":_vm._headers,"items":_vm.selectedUser},scopedSlots:_vm._u([{key:"item.user_type",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(item.user_type))+" ")]}},{key:"item.user_role",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(item.user_role))+" ")]}},{key:"item.organizations",fn:function(ref){
      var item = ref.item;
return _vm._l((item.organizations),function(organization,index){return _c('v-chip',{key:index,staticClass:"mr-2 my-1",attrs:{"x-small":"","label":"","color":organization.color,"dark":""}},[_vm._v(" "+_vm._s(organization.name)+" ")])})}},{key:"item.accounts",fn:function(ref){
      var item = ref.item;
return _vm._l((item.accounts),function(account,index){return _c('v-chip',{key:index,staticClass:"mr-2 my-1",attrs:{"x-small":"","label":"","color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm._f("ucfirst")(account.name))+" ")])})}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openUserForm('edit', item.id)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Edit User")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [(
              _vm.getAuthUser.user_role === 'master' &&
              _vm.getAuthUser.user_type === 'admin'
            )?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.sendPasswordResetMail(item.id)}}},'v-icon',attrs,false),on),[_vm._v("mdi-email-sync-outline")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Reset Password")])]),(
          _vm.getAuthUser.id !== item.id &&
          _vm.getAuthUser.user_role === 'master' &&
          item.user_type !== 'admin'
        )?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e()]}}])}),_c('user-form',{ref:"userForm",attrs:{"formType":_vm.formType,"dialogState":_vm.userFormState},on:{"close-form":_vm.closeUserForm,"message":_vm.message}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }